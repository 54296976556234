:root {
  --colors-green-40: #43d89d;
  --colors-green-50: #35a478;
  --colors-green-60: #006446;
  --colors-grey-0: #fafafa;
  --colors-grey-10: #f5f5f5;
  --colors-grey-20: #eeeeee;
  --colors-grey-30: #e0e0e0;
  --colors-grey-40: #bdbdbd;
  --colors-grey-50: #9e9e9e;
  --colors-grey-60: #757575;
  --colors-grey-70: #616161;
  --colors-grey-80: #424242;
  --colors-grey-90: #212121;
  --colors-grey-100: #000000;
  --colors-red-40: #e2001a;
  --colors-red-50: #b10b1f;
  --colors-red-60: #71010e;
  --colors-red-70: #2e0005;
  --colors-white: #ffffff;
  --fonts-size: 16px;
  --fonts-sans: 'IBM Plex Sans', sans-serif;
}

[data-theme='green'] {
  --colors-red-40: #35a478;
  --colors-red-50: #006446;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
}

body {
  font-size: var(--fonts-size);
  font-family: var(--fonts-sans);
  background: #fff;
}

table {
  border-collapse: collapse;
}

ul {
  padding: 0;
  margin: 0;
}
